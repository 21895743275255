import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { EditGrid } from 'components/editable/editable-grid';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getUseProblemsValidation,
    useRefreshApplicationById,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { moneyNormalizer, numberNormalizer } from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { getCreateAssetSchema } from 'validations/asset';
import { getCreateAssetPartialSchema } from 'validations/asset-partial';

import { AssetFields } from './asset-fields';
import styles from './asset.module.scss';

import type { NewAsset } from 'types/asset';

export type AssetModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId?: number;
};

const asset = {} as NewAsset;

const normalizeAsset = R.evolve({
    amountUsedForDownPayment: moneyNormalizer,
    value: moneyNormalizer,
    year: numberNormalizer,
});

type NewAssetValues = NewAsset & { applicantId: number; totalValue?: number };

export const AssetModal = ({
    editableKey,
    applicationId,
    applicantId,
}: AssetModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } = useModal<AssetModalProps>('createAsset');
    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const { refresh: refreshQualification } = useRefreshQualification();
    const usePartialValidation = useRecoilValue(getUseProblemsValidation);
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const { partialSavingAssetsDownPayment } = useFlags();

    const isEditing = useMemo(
        () => editingKey === editableKey,
        [editableKey, editingKey]
    );

    const partialSchema =
        partialSavingAssetsDownPayment && usePartialValidation;

    const schema = partialSchema
        ? getCreateAssetPartialSchema(i18n)
        : getCreateAssetSchema(i18n);

    const onSubmit = async (values: NewAssetValues) => {
        try {
            const { applicantId, totalValue, ...newAsset } = values;

            const normalizedData = normalizeAsset({
                ...asset,
                ...newAsset,
                value: totalValue, // if we use VALUE as name it triggers an error for REACT SELECT
            });

            await apiClient.createAsset(applicationId, applicantId, {
                ...normalizedData,
            });

            await refreshApplication();
            await refreshApplicationDocumentsCounts();
            await refreshQualification(applicationId);
            await refreshSubmissionNotes();
            closeModal();

            toast.success(
                <SuccessfulToastContent text={i18n._('asset.created')} />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch (error) {
            toast.error(
                <Typography size={0} height={0}>
                    {i18n._('failedToSave')}
                </Typography>,
                {
                    theme: 'colored',
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <EditGrid
            id="createAsset"
            className={cn(styles.grid, styles['grid--modal'])}
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            defaultValues={asset}
            normalizeData={normalizeAsset}
            hideToolbar
        >
            <AssetFields
                isCreating
                isEditing
                asset={asset}
                applicationId={applicationId}
                applicantId={applicantId}
                partialSchema={partialSchema}
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditGrid>
    );
};
