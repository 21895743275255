import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { ProblemType } from 'constants/problem';
import { getProblemsBySection } from 'store/applications';
import { useProblems } from 'utils/use-problems';

import { AssetBaseFields } from './asset-base-fields';
import { AssetCreationFields } from './asset-creation-fields';
import { AssetType } from './asset-type/asset-type';

import type { Asset, AssetTypes } from 'types/asset';

type BaseFieldsProps = {
    // TODO removed when grouped
    applicantName?: string;
    isCreating?: boolean;
    asset: Asset;
    type?: AssetTypes;
    editableKey?: string;
    readonly?: boolean;
    applicantId: number;
};

type Props = {
    applicationId: number;
    isEditing: boolean;
    partialSchema: boolean;
};

export const AssetFields = ({
    asset,
    applicationId,
    applicantId,
    isEditing,
    isCreating,
    editableKey,
    partialSchema,
    readonly = false,
}: BaseFieldsProps & Props) => {
    const methods = useFormContext();

    const { showValidationProblems } = useFlags();

    let typeWatch = asset.type;

    if (methods) {
        const { watch } = methods;

        typeWatch = watch('type', asset.type);
    }

    const sectionProblems = useRecoilValue(
        getProblemsBySection({
            applicationId: applicationId || 0,
            section: ProblemType.assets,
        })
    );

    useProblems(
        showValidationProblems ? sectionProblems : [],
        applicantId,
        asset
    );

    return (
        <>
            {isCreating && (
                <AssetCreationFields
                    isEditing={isEditing}
                    applicationId={applicationId}
                    applicantId={applicantId}
                />
            )}
            {!isCreating && isEditing && (
                <Controller
                    name="type"
                    defaultValue={asset.type}
                    render={({ field }) => <input type="hidden" {...field} />}
                />
            )}
            <AssetBaseFields
                readonly={readonly}
                isCreating={isCreating}
                isEditing={isEditing}
                applicationId={applicationId}
                applicantId={applicantId}
                editableKey={editableKey}
                asset={asset}
                typeWatch={typeWatch}
                partialSchema={partialSchema}
            >
                <AssetType
                    isEditing={isEditing}
                    applicationId={applicationId}
                    applicantId={applicantId}
                    asset={asset}
                    typeWatch={typeWatch}
                    partialSchema={partialSchema}
                />
            </AssetBaseFields>
        </>
    );
};
