import { SupportedTenant, useTenant } from '@nestoca/multi-tenant';

const useFETenantFlags = () => {
    const { slug } = useTenant();

    const shouldShowProductId: Record<SupportedTenant, boolean> = {
        ig: false,
        'ig-dev': false,
        nesto: true,
    };

    return {
        shouldShowProductId: shouldShowProductId[slug],
    };
};

export default useFETenantFlags;
