import React from 'react';

import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { ASSET_TYPE_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicantsSelectOptions } from 'store/applications';
import { useIsTenant } from 'utils/useIsTenant';

import styles from './asset.module.scss';

type Props = {
    isEditing: boolean;
    applicationId: number;
    applicantId: number;
};

export const AssetCreationFields = ({
    isEditing,
    applicationId,
    applicantId,
}: Props) => {
    const { i18n } = useI18n();

    // ******************************************************************************************
    // ****** TODO: THIS SHOULD BE DEPRECATED IN FAVOR OF THE NEW TENANT FEATURES FLAG    *******
    // ****** [epic: SEAL-787](https://nestoca.atlassian.net/browse/SEAL-787)             *******
    // ******                                                                             *******
    // ****** Please be aware that the new tenant features flag is not yet implemented    *******
    // ****** if you have request to add more tenant slug to this condition.              *******
    // ****** remind your P.O. we should build the tenant specific features flag project. *******
    // ****** We want to keep thing scalable and maintainable.                            *******
    // ******                                                                             *******
    // ******************************************************************************************
    const { isIGTenant } = useIsTenant();

    // Get applicants names / id option values
    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    // HELOC should only be an asset for IG tenants
    const assetTypeOptions = ASSET_TYPE_OPTIONS(i18n).filter((option) => {
        if (!isIGTenant) {
            return option.value !== 'PROPERTY' && option.value !== 'HELOC';
        }
        return option.value !== 'PROPERTY';
    });

    return (
        <>
            <EditableCell
                className={styles['applicant-field']}
                required
                isEditing={isEditing}
                fieldType="select"
                name="applicantId"
                label="applicant"
                options={applicantOptions}
                value={applicantId}
            />
            <EditableCell
                className={styles['type-field']}
                required
                isEditing={isEditing}
                name="type"
                label="type"
                fieldType="select"
                options={assetTypeOptions}
                value={''}
            />
        </>
    );
};
