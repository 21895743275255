import { SupportedTenant, useTenant } from '@nestoca/multi-tenant';
import { useTenantFlags } from '@nestoca/multi-tenant';
import { yup } from '@nestoca/validation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { useI18n } from 'providers/i18n/use-i18n';
import { getUseProblemsValidation } from 'store/applications';
import { getApplicantInfoSchema } from 'validations/applicant-info';
import {
    getApplicantInfoIGSchema,
    getApplicantInfoPartialSchema,
} from 'validations/applicant-info-partial';
import { getRegisterAddressSchema } from 'validations/register-address';

type UseApplicantSchemaProps = {
    isCreating?: boolean;
};

export const useApplicantSchema = ({
    isCreating = false,
}: UseApplicantSchemaProps) => {
    const { i18n } = useI18n();
    const { partialSavingIdentification } = useFlags();
    const { requireCoApplicantAddress } = useTenantFlags();
    const { slug } = useTenant();

    const usePartialValidation = useRecoilValue(getUseProblemsValidation);

    let schema;

    schema =
        partialSavingIdentification && usePartialValidation
            ? getApplicantInfoPartialSchema(i18n)
            : getApplicantInfoSchema(i18n, isCreating);

    if (requireCoApplicantAddress) {
        schema = yup.object().shape({
            ...schema.fields,
            ...getRegisterAddressSchema(i18n).fields,
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is a dynamic schema
    const tenantSchema: Record<SupportedTenant, any> = {
        ig: yup.object().shape({
            ...schema.fields,
            ...getApplicantInfoIGSchema(i18n).fields,
        }),
        'ig-dev': yup.object().shape({
            ...schema.fields,
            ...getApplicantInfoIGSchema(i18n).fields,
        }),
        nesto: schema,
    };

    return tenantSchema[slug];
};
