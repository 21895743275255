import React, { Fragment } from 'react';

import { Flex, Grid } from '@nestoca/ui';
import { Edit } from 'grommet-icons';
import { Controller } from 'react-hook-form';
import { constSelector, useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { Tooltip } from 'components/tooltip';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { getPropertyById } from 'store/applications';
import { useEditingRights } from 'store/rights';
import { Asset, AssetProperty } from 'types/asset';
import { useEditingContext } from 'utils/use-editing-context';
import { compareForId } from 'utils/validations/comparators';

import styles from './asset.module.scss';

type Props = {
    children: React.ReactNode;
    readonly: boolean;
    isCreating?: boolean;
    isEditing: boolean;
    applicationId: number;
    applicantId: number;
    editableKey?: string;
    asset: Asset;
    typeWatch: Asset['type'];
    partialSchema: boolean;
};

export const AssetBaseFields = ({
    children,
    readonly,
    isCreating,
    isEditing,
    applicationId,
    applicantId,
    editableKey,
    asset,
    typeWatch,
    partialSchema,
}: Props) => {
    const { i18n } = useI18n();

    const { setEditingKey } = useEditingContext();

    const hasEditingRights = useEditingRights();

    const { bridgeLoanAmount } = useRecoilValue(
        typeWatch === 'PROPERTY'
            ? getPropertyById({
                  applicationId,
                  propertyId: (asset as AssetProperty).existingPropertyId,
              })
            : constSelector({ bridgeLoanAmount: null })
    );

    return (
        <Fragment key={`baseFields-${applicantId}-${asset.id}`}>
            {isEditing && typeWatch === 'PROPERTY' && (
                <Controller
                    name="existingPropertyId"
                    defaultValue={(asset as AssetProperty).existingPropertyId}
                    render={({ field }) => <input type="hidden" {...field} />}
                />
            )}
            <EditableCell
                className={styles['value-field']}
                required={!partialSchema}
                isEditing={isEditing}
                name="totalValue"
                label="totalValue"
                fieldType="money"
                disabled={asset.type === 'PROPERTY'}
                gridArea={isCreating ? null : 'value'}
                value={asset.value}
                variant="lightGray"
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            {typeWatch !== 'VEHICLE' && (
                <Tooltip
                    content={i18n._('bridgeLoanAmountRequested')}
                    disabled={!bridgeLoanAmount}
                    placement="right"
                    type="info"
                >
                    <EditableCell
                        className={styles['down-payment-field']}
                        required
                        isEditing={bridgeLoanAmount ? false : isEditing}
                        name="amountUsedForDownPayment"
                        label="downPayment"
                        fieldType="money"
                        gridArea={
                            isCreating ? null : 'amountUsedForDownPayment'
                        }
                        value={asset.amountUsedForDownPayment}
                        variant="lightGray"
                        isFieldInvalidCompareFn={compareForId(asset)}
                        problemType={ProblemType.assets}
                    />
                </Tooltip>
            )}
            {isCreating || typeWatch === 'PROPERTY' ? (
                children
            ) : (
                <Grid className={styles.grid} gap={4}>
                    {children}
                </Grid>
            )}
            {!readonly && !isEditing && editableKey && hasEditingRights && (
                <Flex
                    data-testid="edit-asset"
                    align="center"
                    onClick={() => setEditingKey(editableKey)}
                    style={{ gridArea: 'editButton', cursor: 'pointer' }}
                >
                    <Edit color="currentColor" size="14px" />
                </Flex>
            )}
        </Fragment>
    );
};
