import React from 'react';

import { useTenant } from '@nestoca/multi-tenant';
import css from '@styled-system/css';
import { Image } from 'grommet';
import { Flex } from 'reflexbox/styled-components';

import { Text } from 'components/text/text';
import { getLender } from 'constants/lenderConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import useFETenantFlags from 'utils/use-flags-config';

type Props = {
    lenderId: number;
    productName: string;
    productId?: string;
};

export const LenderHeader = ({ lenderId, productName, productId }: Props) => {
    const lender = getLender(lenderId);
    const { shouldShowProductId } = useFETenantFlags();

    const {
        i18n: { locale },
    } = useI18n();

    const tenant = useTenant();

    return (
        <Flex mb="16px">
            <Image
                alt={tenant?.displayName || 'logo lender'}
                src={tenant?.logo?.secondary[locale]?.url}
                height="40px"
            />

            <Flex flexDirection="column" ml="8px">
                <Flex justifyContent="space-between">
                    <Text
                        css={css({
                            color: 'gray-1',
                            fontSize: 2,
                            fontWeight: 'bold',
                        })}
                        tx={lender.namePath}
                    />
                    {shouldShowProductId && productId && (
                        <Text
                            css={css({
                                color: 'gray-1',
                                fontSize: 1,
                            })}
                        >
                            {productId}
                        </Text>
                    )}
                </Flex>
                <Text
                    css={css({
                        color: 'gray-2',
                    })}
                >
                    {productName}
                </Text>
            </Flex>
        </Flex>
    );
};
