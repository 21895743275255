import React from 'react';

import { SupportedTenant, useTenant } from '@nestoca/multi-tenant';

import { ApplicantInfo } from 'types/applicant';
import { ApplicationProblemsGroupedWithApplicants } from 'types/problems';

import { ApplicantInfoIGExtraFields } from '.';

export interface UseTenantFieldsProps {
    applicant: ApplicantInfo;
    problems: ApplicationProblemsGroupedWithApplicants;
}

export const useApplicantInfoTenantFields = (props: UseTenantFieldsProps) => {
    const { slug } = useTenant();
    const tenantFields: Record<SupportedTenant, React.ReactNode | null> = {
        ig: <ApplicantInfoIGExtraFields {...props} />,
        'ig-dev': <ApplicantInfoIGExtraFields {...props} />,
        nesto: null,
    };

    const result = tenantFields[slug];

    return result;
};
