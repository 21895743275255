import React from 'react';

import { EditableCell } from 'components/editable';
import { ProblemType } from 'constants/problem';
import { AssetOther } from 'types/asset';
import { compareForId } from 'utils/validations/comparators';

type Props = {
    isEditing: boolean;
    asset: AssetOther;
    partialSchema: boolean;
};

export const OtherAsset = ({ isEditing, asset, partialSchema }: Props) => {
    const { description, name } = asset;

    return (
        <>
            <EditableCell
                required={!partialSchema}
                isEditing={isEditing}
                name="name"
                label="name"
                value={name}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialSchema}
                isEditing={isEditing}
                name="description"
                label="expandedRatePage.notes.description"
                value={description}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
        </>
    );
};
