import { useFlags } from 'launchdarkly-react-client-sdk';

import { EditableCell } from 'components/editable';
import { formatAsSIN } from 'utils';
import { useProblems } from 'utils/use-problems';
import { compareForApplicant } from 'utils/validations/comparators';

import { UseTenantFieldsProps } from './use-applicant-info-tenant-fields';

export const ApplicantInfoIGExtraFields = ({
    applicant,
    problems,
}: UseTenantFieldsProps) => {
    const { showValidationProblems } = useFlags();

    useProblems(showValidationProblems ? problems : [], applicant.applicantId);

    return (
        <EditableCell
            isEditing
            name="sin"
            fieldType="text"
            label="socialInsuranceNumber"
            value={formatAsSIN(applicant.sin)}
            isFieldInvalidCompareFn={compareForApplicant(applicant)}
        />
    );
};
