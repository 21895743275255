import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { EditableGrid } from 'components/editable';
import { Sections } from 'components/liabilities/sections';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getUseProblemsValidation,
    useRefreshApplicationById,
    getApplicantsSelectOptions,
    getMainApplicantId,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import { useRefreshSubmissionNotes } from 'store/submission-notes';
import { moneyNormalizer } from 'utils';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import { getCreateLiabilitySchema } from 'validations/liability';
import { getCreateLiabilityPartialSchema } from 'validations/liability-partial';

import type { Liability, LiabilityFormData } from 'types/applicant';

export type LiabilityModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId: number;
};

const normalizeLiability = R.evolve({
    coOwnerApplicantIds: (value) => (Array.isArray(value) ? value : []),
    balance: moneyNormalizer,
    highCredit: moneyNormalizer,
    included: Boolean,
    payment: {
        amount: moneyNormalizer,
    },
});

export const LiabilityModal = ({
    editableKey,
    applicationId,
}: LiabilityModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } =
        useModal<LiabilityModalProps>('createLiability');

    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);

    const { partialSavingLiabilities } = useFlags();
    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const usePartialValidation = useRecoilValue(getUseProblemsValidation);

    const mainApplicantId = useRecoilValue(getMainApplicantId(applicationId));

    const applicantsSelectOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const liability: Liability = {
        id: null,
        applicantId: mainApplicantId,
        included: true,
        type: null,
        coOwnerApplicantIds: null,
        highCredit: null,
        balance: null,
        currency: 'CAD',
        creditType: null,
        description: null,
        paymentMultiplier: null,
        payoff: null,
        fromCreditBureau: false,
        payment: {
            amount: null,
            frequency: null,
        },
    };

    const isEditing = useMemo(() => editingKey === editableKey, [editingKey]);

    const partialSchema = partialSavingLiabilities && usePartialValidation;

    const schema = partialSchema
        ? getCreateLiabilityPartialSchema(i18n)
        : getCreateLiabilitySchema(i18n);

    const onSubmit = async (
        values: LiabilityFormData & { applicantId: number }
    ) => {
        try {
            const normalizedData = normalizeLiability({
                ...liability,
                ...values,
            });

            await apiClient.createLiability(
                applicationId,
                values.applicantId,
                normalizedData
            );

            await Promise.all([
                refreshApplication(),
                refreshApplicationDocumentsCounts(),
                refreshQualification(applicationId),
                refreshSubmissionNotes(),
            ]);

            closeModal();

            toast.success(
                <SuccessfulToastContent text={i18n._('liability.created')} />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch (error) {
            toast.error(
                <Typography size={0} height={1}>
                    {i18n._('failedToSave')}
                </Typography>,
                {
                    theme: 'colored',
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <EditableGrid
            id="create-liability"
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            defaultValues={liability}
            editableKey={editableKey}
            gridTemplateColumns="1fr"
            gridGap="var(--spacing-4)"
            hideToolbar
        >
            <Sections
                isCreating
                isEditing={isEditing}
                applicationId={applicationId}
                applicantsSelectOptions={applicantsSelectOptions}
                liability={liability}
                partialSchema={partialSchema}
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditableGrid>
    );
};
