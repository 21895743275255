import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Flex, Typography } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { omit } from 'ramda';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { EditableCell, EditableGrid } from 'components/editable';
import { ModalFormActions } from 'components/modals/modal-form-actions';
import { SuccessfulToastContent } from 'components/successful-toast-content';
import { INCOME_TYPE } from 'constants/appConstants';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getApplicantsSelectOptions,
    getUseProblemsValidation,
    useRefreshApplicationById,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import { useRefreshQualification } from 'store/qualification';
import {
    useRefreshDocumentValidation,
    useRefreshSubmissionNotes,
} from 'store/submission-notes';
import { useEditingContextWithCleanup } from 'utils/use-editing-context';
import {
    getCreateEmploymentSchema,
    normalizeEmployment,
} from 'validations/employment';
import { getCreateEmploymentPartialSchema } from 'validations/employment-partial';

import { EmploymentType } from './employment-type';

import type { IncomeEmployment } from 'types/applicant';

export type EmploymentModalProps = {
    editableKey: string;
    applicationId: number;
    applicantId?: number;
};

const employment = {} as IncomeEmployment;

type NewEmployment = IncomeEmployment & { applicantId: number };

export const EmploymentModal = ({
    editableKey,
    applicationId,
    applicantId,
}: EmploymentModalProps) => {
    const { i18n } = useI18n();
    const { close: closeModal } =
        useModal<EmploymentModalProps>('createEmployment');
    const { partialSavingEmployment } = useFlags();
    const usePartialValidation = useRecoilValue(getUseProblemsValidation);

    const refreshApplication = useRefreshApplicationById(applicationId);
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const refreshSubmissionNotes = useRefreshSubmissionNotes(applicationId);
    const refreshDocumentValidation =
        useRefreshDocumentValidation(applicationId);
    const { refresh: refreshQualification } = useRefreshQualification();

    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    const { editingKey } = useEditingContextWithCleanup(editableKey);

    const isEditing = useMemo(
        () => editingKey === editableKey,
        [editableKey, editingKey]
    );

    const partialSchema = partialSavingEmployment && usePartialValidation;

    const schema = partialSchema
        ? getCreateEmploymentPartialSchema(i18n)
        : getCreateEmploymentSchema(i18n);

    const onSubmit = async (values: NewEmployment) => {
        try {
            const newEmployment: IncomeEmployment = omit(
                ['applicantId'],
                values
            );

            const normalizedData = normalizeEmployment({
                ...employment,
                ...newEmployment,
            });

            await apiClient.createEmployment(
                applicationId,
                values.applicantId,
                normalizedData
            );

            await refreshApplication();
            await refreshApplicationDocumentsCounts();
            await refreshQualification(applicationId);
            await refreshSubmissionNotes();
            await refreshDocumentValidation();
            closeModal();

            toast.success(
                <SuccessfulToastContent text={i18n._('employment.created')} />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        } catch (error) {
            toast.error(
                <Typography size={0} height={0}>
                    {i18n._('failedToSave')}
                </Typography>,
                {
                    theme: 'colored',
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <EditableGrid
            id={`employment-${employment.id}`}
            onSubmit={onSubmit}
            resolver={yupResolver(schema)}
            editableKey={editableKey}
            gridGap="var(--spacing-4)"
            gridTemplateColumns="1fr"
            hideToolbar
        >
            <Flex direction="column" gap={4}>
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('applicant')}
                </Typography>
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="applicantId"
                    label="applicant"
                    fieldType="select"
                    options={applicantOptions}
                    value={applicantId}
                />
            </Flex>
            <Flex direction="column" gap={4}>
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('Income Type')}
                </Typography>
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="incomeType"
                    label="Income Type"
                    fieldType="select"
                    options={INCOME_TYPE(i18n)}
                    value={''}
                />
            </Flex>
            <EmploymentType
                isCreating
                isEditing={isEditing}
                employment={employment}
                partialSchema={partialSchema}
            />
            {isEditing && <ModalFormActions closeModal={closeModal} />}
        </EditableGrid>
    );
};
