import React from 'react';

import { EditableCell } from 'components/editable';
import { FAMILY_RELATIONSHIP_OPTIONS } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { AssetGift } from 'types/asset';
import { compareForId } from 'utils/validations/comparators';

type Props = {
    isEditing: boolean;
    asset: AssetGift;
    partialSchema: boolean;
};

export const GiftAsset = ({ isEditing, asset, partialSchema }: Props) => {
    const { i18n } = useI18n();

    const { donorFirstName, donorLastName, familyRelationship } = asset;

    return (
        <>
            <EditableCell
                required={!partialSchema}
                isEditing={isEditing}
                name="donorFirstName"
                label="First Name"
                value={donorFirstName}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialSchema}
                isEditing={isEditing}
                name="donorLastName"
                label="Last Name"
                value={donorLastName}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
            <EditableCell
                required={!partialSchema}
                isEditing={isEditing}
                name="familyRelationship"
                label="assets.familyRelationship"
                fieldType="select"
                options={FAMILY_RELATIONSHIP_OPTIONS(i18n)}
                value={familyRelationship}
                isFieldInvalidCompareFn={compareForId(asset)}
                problemType={ProblemType.assets}
            />
        </>
    );
};
